import React, { useState } from "react"
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { CalendlyEventListener } from 'react-calendly';
import MailSignupForm from '../components/mailSignupForm';

const useStyles = makeStyles((theme) => ({
  callBox: {
    backgroundColor: `rgb(240, 229, 215)`,
    paddingTop: `3rem`,
    paddingBottom: `3rem`
  },
  previewLinks: {
    textAlign: `center`,
    paddingTop: `2rem`,
    paddingBottom: `2rem`,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: `3rem`,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: `6rem`,
    },
  },
  performTitle: {
    color: `rgb(16,16,17)`,
    // fontSize: `7rem`,
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: `4rem`
    // },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: `2rem`
    // }
  },
  performImg: {
    [theme.breakpoints.down('sm')]: {
      height: `250px`
    }
  },
  btnWrapper: {
    paddingBottom: `2rem`,
    paddingTop: `2rem`
  },
  signupText: {
    marginBottom: '1.5rem',
  }
}))

const CallToAction = () => {
  const heights = {
    DATE_TIME_SCHEDULED: 1400,
    EVENT_TYPE_VIEWED: 1100,
    EVENT_SCHEDULED: 800
  }

  const classes = useStyles();
  const [iframeHeight, setIframeHeight] = useState(heights.EVENT_TYPE_VIEWED);

  const handleDateTimeSelected = (e) => setIframeHeight(heights.DATE_TIME_SCHEDULED);
  const handleTypeViewed = () => setIframeHeight(heights.EVENT_TYPE_VIEWED);
  const handleEventScheduled = () => setIframeHeight(heights.EVENT_SCHEDULED);

  return (
    <Box className={classes.callBox}>
      {/* <Container maxWidth="md">
        <Box className={classes.previewLinks}>
          <Typography variant='h2' component='h2' className={classes.performTitle}>
            New Student Consultation
          </Typography>
          <Box className={classes.btnWrapper}>
            <CalendlyEventListener
              onDateAndTimeSelected={handleDateTimeSelected}
              onEventScheduled={handleEventScheduled}
              onEventTypeViewed={handleTypeViewed}
            >
              <div
                style={{
                  height: `${iframeHeight}px`,
                  minWidth: '320px'
                }}
              >
                <iframe
                  title="Calendly New Student Consultation"
                  frameBorder="0"
                  height={`${iframeHeight}px`}
                  src="https://calendly.com/luxperformingarts/30min?embed_domain=luxperformingarts.com&embed_type=Inline"
                  width="100%"
                />
              </div>
            </CalendlyEventListener>
          </Box>
        </Box>
      </Container> */}
      <Container maxWidth="md" className={classes.btnWrapper}>
        <Typography variant='h2' component='h2' align="center" className={classes.signupText}>
          Sign Up for our e-mail list!
        </Typography>
        <MailSignupForm />
      </Container>
    </Box>
  )
}

export default CallToAction