import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Box from '@material-ui/core/Box'
import PreviewCompatibleImage from './previewCompatibleImage'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  performPrevBox: {
    backgroundColor: `rgb(7,0,178)`
  },
  previewLinks: {
    display: `flex`,
    justifyContent: `center`,
    paddingTop: `2rem`,
    paddingBottom: `2rem`,
  },
  performTitle: {
    color: `rgb(244,255,255)`,
    fontSize: `1.5rem`,
    [theme.breakpoints.down('md')]: {
      fontSize: `1rem`
    }
  },
  performImg: {
    [theme.breakpoints.down('sm')]: {
      height: `250px`
    }
  }
}))

const PerformPreview = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "_JPS0064.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const classes = useStyles()

  return (
    <Box className={classes.performPrevBox}>
      <PreviewCompatibleImage
        imageInfo={{
          image: data.file,
          alt: `Spring Awakening performance picture, July 2021`,
        }}
        className={classes.performImg}
      />
      <Container maxWidth="lg">
        <Box className={classes.previewLinks}>
          <Typography variant='h2' component='h2' className={classes.performTitle}>
            <b>Spring Awakening</b>, White Eagle Hall, Jersey City, 2021
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default PerformPreview