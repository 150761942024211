import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Box from '@material-ui/core/Box'
import PreviewCompatibleImage from './previewCompatibleImage'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  schoolPrevBox: {
    backgroundColor: `rgb(16,16,17)`
  },
  previewLinks: {
    display: `flex`,
    justifyContent: `center`,
    paddingTop: `2rem`,
    paddingBottom: `2rem`
  },
  previewItem: {
    fontSize: `1.5rem`,
    color: `rgb(244,255,255)`,
    [theme.breakpoints.down('md')]: {
      fontSize: `1rem`
    },
    borderBottom: `2px solid rgb(16,16,17)`,
    borderRadius: `0px`,
  },
  previewLinksWrapper: {
    display: `flex`,
    justifyContent: `center`,
  },
  classesAtLuxImgGrid: {
    paddingBottom: `24px`,
  },
}))

const SchoolPreview = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: {
            regex: "/(jpg)|(jpeg)|(png)/"}, 
            relativeDirectory: {
              eq: "schoolAtLux"
            }
          },
        sort: {
          fields: name,
          order: ASC

        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const { edges: images } = data.allFile
  const classes = useStyles()
  const [ currImgIndex, setCurrImgIndex ] = useState(0)
  const currImgIndexRef = useRef(currImgIndex)
  currImgIndexRef.current = currImgIndex

  useEffect(() => {
    const interval = setInterval(() => {
      if (currImgIndexRef.current < 2){
        setCurrImgIndex(currImgIndexRef.current + 1)
      } else {
        setCurrImgIndex(0)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Box className={classes.schoolPrevBox}>
      <Container maxWidth="lg">
        <Box className={classes.previewLinks}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={3} className={classes.previewLinksWrapper}>
              <Typography className={classes.previewItem}>BROADWAY</Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.previewLinksWrapper}>
              <Typography className={classes.previewItem}>ACTING & FILM</Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.previewLinksWrapper}>
              <Typography className={classes.previewItem}>SONGWRITING</Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.previewLinksWrapper}>
              <Typography className={classes.previewItem}>PRIVATE LESSONS</Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} className={classes.classesAtLuxImgGrid}>
          <Hidden xsDown>
            {images && images.map(({ node: img }, i) => (
              <Grid item sm={4} xs={12} key={i} className={classes.imgSchoolPreview}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: img,
                    alt: `school preview performance image`,
                  }}
                />
              </Grid>
            ))}
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12} key={images[currImgIndex].node.id} className={classes.imgSchoolPreview}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: images[currImgIndex].node,
                  alt: `school preview performance image`,
                }}
                className={classes.imgMobile}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Box>
  )
}

export default SchoolPreview