import React, {useState} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewCompatibleImage from './previewCompatibleImage'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Link } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: `35px`,
    paddingBottom: `45px`,
    paddingLeft: `0px !important`,
    paddingRight: '0px !important',
  },
  postRoll: {
    backgroundColor: `rgb(16,16,17)`,
    minHeight: `450px`,
    marginTop: `-60px`,
    padding: `25px 60px`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `space-evenly`,
    [theme.breakpoints.down('sm')]: {
      padding: `25px 25px`
    }
  },
  postRollSm: {
    marginTop: `0px`,
    maxHeight: `none`
  },
  blogItem: {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `baseline`,
    fontSize: `24px`,
    '&:hover': {
      backgroundColor: `rgb(34,32,32)`
    }
  },
  blogItemDate: {
    color: `rgb(254, 255, 255)`,
    fontSize: `1.25rem`,
    margin: `0px`
  },
  blogItemTitle: {
    color: `rgb(219, 202, 144)`,
    fontSize: `1.5rem`,
    fontWeight: `bold`,
    margin: `0px`
  },
  holder: {
    overflow: `hidden`,
    position: `relative`,
    width: `200px`,
    height: `50px`
  },
  ellipse: {
    position: `absolute`,
    background: `radial-gradient(ellipse, transparent, transparent 7px, white 7px, white 10px, transparent 11px)`,
    backgroundSize: `36px 40px`,
    width: `200px`,
    height: `20px`
  },
  ellipse2: {
    top: `20px`,
    left: `18px`,
    backgroundPosition: `0px -20px`
  },
  squigglyWrapper: {
    display: `flex`,
    justifyContent: `center`
  },
  featuredPost: {
    color: `black`,
    fontSize: `40px`,
  },
  imgContainer: {
    overflow: `hidden`
  },
  readMoreImgHov: {
    transition: `all 0.3s ease`,  
    transform: `scale(1.05)`
  },
  readMore: {
    fontStyle: `italic`,
    color: `#fe66cb`
  },
  featuredPostTitleWrapper: {
    padding: `10px`,
    display: `flex`,
    justifyContent: `center`
  },
  noMargin: {
    margin: `0px`
  }
}))

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const BlogPreview = () => {
  const classes = useStyles()
  const theme = useTheme()
  const screenMedium = useMediaQuery(theme.breakpoints.down('sm'))

  const [isHovered, setHovered] = useState(false)

  const handleImgTrans = (isHovering) => {
    setHovered(isHovering)
  }

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              date(formatString: "MMMM DD, YYYY")
              featuredpost
              author
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { edges: posts } = data.allMarkdownRemark
  const latestPost = posts[0].node

  const latestPosts = [posts[1], posts[2], posts[3]];

  return (
    <Container className={classes.root} maxWidth="xl">
      <Grid container spacing={0}>
        <Grid item lg={9} md={7} sm={12} xs={12} className={classes.imgContainer}>
          <Link to={`/latestAtLux${latestPost.fields.slug}`} onMouseEnter={() => handleImgTrans(true)} onMouseLeave={() => handleImgTrans(false)}>
            <PreviewCompatibleImage
              imageInfo={{
                image: latestPost.frontmatter.featuredimage,
                alt: `featured image thumbnail for post ${latestPost.frontmatter.title}`,
              }}
              className={clsx({
                [classes.readMoreImgHov] : isHovered})}
            />
            <Container className={classes.featuredPostTitleWrapper}>
              <div>
                <Typography variant="h2" component="h2" className={classes.featuredPost}>
                  {latestPost.frontmatter.title}
                </Typography>
                <Typography variant="h6" component='h6' className={classes.readMore}>
                  READ MORE
                </Typography>
              </div>
            </Container>
          </Link>
        </Grid>
        <Grid item lg={3} md={5} sm={12} xs={12} 
          className={clsx({
            [classes.postRoll] : true,
            [classes.postRollSm] : screenMedium})}>
          <SquigglyLines />
          <List>
            {latestPosts &&
              latestPosts.map(({ node: post }) => (
                <ListItemLink href={`/latestAtLux${post.fields.slug}`} className={classes.blogItem} key={post.id}>
                  <ListItemText primary={post.frontmatter.title} classes={{root: classes.noMargin, primary: classes.blogItemTitle}}/>
                  <ListItemText primary={post.frontmatter.date} classes={{root: classes.noMargin, primary: classes.blogItemDate}} />
                  <Typography variant="h6" component='h6' className={classes.readMore} style={{fontSize: `16px`}}>
                    READ MORE
                  </Typography>
                </ListItemLink>
              ))}
          </List>
          <SquigglyLines />
        </Grid>
      </Grid>
    </Container>
  )
}

const SquigglyLines = () => {
  const classes = useStyles()
  return (
    <div className={classes.squigglyWrapper}>
      <div className={classes.holder}>
        <div className={classes.ellipse}></div>
        <div className={clsx(classes.ellipse, classes.ellipse2)}></div>
      </div>
    </div>
  )
}

export default BlogPreview
