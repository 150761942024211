import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import addToMailchimp from 'gatsby-plugin-mailchimp';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  subscribeForm: {
    padding: '1rem',
  },
  button: {
    background: '#fe66cb',
    border: 0,
    borderRadius: 0,
    boxShadow: '8px 6px #fe3324',
    color: 'white',
    padding: '0 30px',
    fontWeight: '900',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: `24px`,
    zIndex: `1000`,
    maxWidth: `300px`,
  },
  formFields: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  message: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'center',
    fontSize: '1.4rem',
  }
});

const MailSignupForm = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');

  const handleNameChange = (event) => setName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email, { FNAME: name, LNAME: lastName });
    setMessage(result.msg);
  }

  return (
    <form name="subscribeForm" method="POST" id="subscribe-form" className={classes.subscribeForm} onSubmit={handleSubmit}>
      <div className={classes.formFields}>
        <TextField
          id="name-form-input"
          label="First Name"
          name="First name"
          variant="outlined"
          type="text"
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          id="last-name-form-input"
          label="Last Name"
          name="Last name"
          variant="outlined"
          type="text"
          value={lastName}
          onChange={handleLastNameChange}
        />
        <TextField
          id="email-form-input"
          label="E-mail"
          name="email"
          variant="outlined"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
        <Button variant="contained" color="secondary" className={classes.button} type="submit">
          SUBSCRIBE
        </Button>
      </div>
      <div className={classes.message} dangerouslySetInnerHTML={{ __html: message}} />
    </form>
  )
}

export default MailSignupForm;