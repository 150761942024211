import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LuxBtn from '../components/luxBtn'
import { makeStyles } from '@material-ui/core/styles'
import BlogPreview from '../components/blogPreview'
import SchoolPreview from '../components/schoolPreview'
import PerformPreview from '../components/performPreview'
import CallToAction from '../components/callToAction'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  sandwich: {
    height: `0px`,
    zIndex: 99,
    paddingTop: 90,
  },
  sandwichContainer: {
    paddingLeft: '0px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '42px',
    },
  },
  indexWrapper: {
    backgroundColor: `rgb(254, 255, 255)`
  }
}));

const IndexPage = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.indexWrapper}>
        <div className={classes.sandwich}>
          <Container maxWidth="lg" className={classes.sandwichContainer}>
            <AniLink paintDrip to={`/latestAtLux/`} hex="#fe66cb">
              <LuxBtn text={`LATEST at LUX`} />
            </AniLink>
          </Container>
        </div>
        <BlogPreview />
        <div className={classes.sandwich}>
          <Container maxWidth="lg" className={classes.sandwichContainer}>
            <LuxBtn text={`CLASSES at LUX`} />
          </Container>
        </div>
        <SchoolPreview />
        <div className={classes.sandwich}>
          <Container maxWidth="lg" className={classes.sandwichContainer}>
            <LuxBtn text={`PRODUCTIONS`} />
          </Container>
        </div>
        <PerformPreview />
        <CallToAction />
      </div>
    </Layout>
  )
}

export default IndexPage
